export const API_INVOCATION = "API_INVOCATION";

//authentication related actions
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const GET_TOKEN_VALIDATION = "GET_TOKEN_VALIDATION";
export const GET_TOKEN_SUCCESS = "GET_TOKEN_SUCCESS";
export const GET_TOKEN_ERROR = "GET_TOKEN_ERROR";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";
export const UPDATE_NAME = "UPDATE_NAME";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";

//user related actions
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR";

export const CREATE_USERS = "CREATE_USERS";
export const CREATE_USERS_SUCCESS = "CREATE_USERS_SUCCESS";
export const CREATE_USERS_ERROR = "CREATE_USERS_ERROR";

export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS";
export const UPDATE_USERS_ERROR = "UPDATE_USERS_ERROR";

export const DELETE_USERS = "DELETE_USERS";
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS";
export const DELETE_USERS_ERROR = "DELETE_USERS_ERROR";

export const USER_LIST = "USER_LIST";
export const SELECTED_USER = "SELECTED_USER";

//category related actions
export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST";
export const SELECTED_CATEGORY = "SELECTED_CATEGORY";
export const SET_ACTIVE_CATEGORY = "SET_ACTIVE_CATEGORY";

export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_ERROR = "GET_ALL_CATEGORIES_ERROR";

export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_ERROR = "CREATE_CATEGORY_ERROR";

export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_ERROR = "UPDATE_CATEGORY_ERROR";

export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_ERROR = "DELETE_CATEGORY_ERROR";

export const SEARCH_CATEGORY = "SEARCH_CATEGORY";
export const SEARCH_CATEGORY_SUCCESS = "SEARCH_CATEGORY_SUCCESS";
export const SEARCH_CATEGORY_ERROR = "SEARCH_CATEGORY_ERROR";

export const ACTIVE_CATEGORY = "ACTIVE_CATEGORY";
export const ACTIVE_CATEGORY_SUCCESS = "ACTIVE_CATEGORY_SUCCESS";
export const ACTIVE_CATEGORY_ERROR = "ACTIVE_CATEGORY_ERROR";

//group related actions
export const SET_GROUP_LIST = "SET_GROUP_LIST";
export const SELECTED_GROUP = "SELECTED_GROUP";

export const GET_ALL_GROUPS = "GET_ALL_GROUPS";
export const GET_ALL_GROUPS_SUCCESS = "GET_ALL_GROUPS_SUCCESS";
export const GET_ALL_GROUPS_ERROR = "GET_ALL_GROUPS_ERROR";

export const CREATE_GROUP = "CREATE_GROUP";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_ERROR = "CREATE_GROUP_ERROR";

export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_ERROR = "UPDATE_GROUP_ERROR";

export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_ERROR = "DELETE_GROUP_ERROR";

export const ACTIVE_GROUP = "ACTIVE_GROUP";
export const ACTIVE_GROUP_SUCCESS = "ACTIVE_GROUP_SUCCESS";
export const ACTIVE_GROUP_ERROR = "ACTIVE_GROUP_ERROR";

// Product related actions
export const SELECTED_PRODUCT = "SELECTED_PRODUCT";

export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_ERROR = "GET_ALL_PRODUCTS_ERROR";

export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";

export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_ERROR = "CREATE_PRODUCT_ERROR";

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_ERROR = "UPDATE_PRODUCT_ERROR";

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_ERROR = "DELETE_PRODUCT_ERROR";

export const UPLOAD_PRODUCT_IMAGES = "UPLOAD_PRODUCT_IMAGES";
export const UPLOAD_PRODUCT_IMAGES_SUCCESS = "UPLOAD_PRODUCT_IMAGES_SUCCESS";
export const UPLOAD_PRODUCT_IMAGES_ERROR = "UPLOAD_PRODUCT_IMAGES_ERROR";

export const UPDATE_COVER_IMAGE = "UPDATE_COVER_IMAGE";
export const UPDATE_COVER_IMAGE_SUCCESS = "UPDATE_COVER_IMAGE_SUCCESS";
export const UPDATE_COVER_IMAGE_ERROR = "UPDATE_COVER_IMAGE_ERROR";

export const DELETE_IMAGE = "DELETE_IMAGE";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_ERROR = "DELETE_IMAGE_ERROR";

export const GET_PRODUCT_REVIEWS = "GET_PRODUCT_REVIEWS";
export const GET_PRODUCT_REVIEWS_SUCCESS = "GET_PRODUCT_REVIEWS_SUCCESS";
export const GET_PRODUCT_REVIEWS_ERROR = "GET_PRODUCT_REVIEWS_ERROR";

export const EMPTY_SELECTED_PRODUCT = "EMPTY_SELECTED_PRODUCT";

//User Details management
export const GET_ALL_USERS_DETAILS = "GET_ALL_USERS_DETAILS";
export const GET_ALL_USERS_DETAILS_SUCCESS = "GET_ALL_USERS_DETAILS_SUCCESS";
export const GET_ALL_USERS_DETAILS_ERROR = "GET_ALL_USERS_DETAILS_ERROR";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_ERROR = "GET_USER_DETAILS_ERROR";

export const GET_USER_ADDRESS = "GET_USER_ADDRESS";
export const GET_USER_ADDRESS_SUCCESS = "GET_USER_ADDRESS_SUCCESS";
export const GET_USER_ADDRESS_ERROR = "GET_USER_ADDRESS_ERROR";

export const GET_USER_PURCHASE_INFORMATION = "GET_USER_PURCHASE_INFORMATION";
export const GET_USER_PURCHASE_INFORMATION_SUCCESS = "GET_USER_PURCHASE_INFORMATION_SUCCESS";
export const GET_USER_PURCHASE_INFORMATION_ERROR = "GET_USER_PURCHASE_INFORMATION_ERROR";

export const GET_USER_REVIEW_HISTORY = "GET_USER_REVIEW_HISTORY";
export const GET_USER_REVIEW_HISTORY_SUCCESS = "GET_USER_REVIEW_HISTORY_SUCCESS";
export const GET_USER_REVIEW_HISTORY_ERROR = "GET_USER_REVIEW_HISTORY_ERROR";

export const DEACTIVATE_ACCOUNT = "DEACTIVATE_ACCOUNT";
export const DEACTIVATE_ACCOUNT_SUCCESS = "DEACTIVATE_ACCOUNT_SUCCESS";
export const DEACTIVATE_ACCOUNT_ERROR = "DEACTIVATE_ACCOUNT_ERROR";

export const ACTIVATE_ACCOUNT = "ACTIVATE_ACCOUNT";
export const ACTIVATE_ACCOUNT_SUCCESS = "ACTIVATE_ACCOUNT_SUCCESS";
export const ACTIVATE_ACCOUNT_ERROR = "ACTIVATE_ACCOUNT_ERROR";

//Sales Management
export const SET_SALES_LIST = "SET_SALES_LIST";
export const SELECTED_SALES = "SELECTED_SALES";

export const GET_ALL_SALES_DETAILS = "GET_ALL_SALES_DETAILS";
export const GET_ALL_SALES_DETAILS_SUCCESS = "GET_ALL_SALES_DETAILS_SUCCESS";
export const GET_ALL_SALES_DETAILS_ERROR = "GET_ALL_SALES_DETAILS_ERROR";

// Review related actions
export const SELECTED_REVIEW = "SELECTED_REVIEW";

export const GET_ALL_REVIEWS = "GET_ALL_REVIEWS";
export const GET_ALL_REVIEWS_SUCCESS = "GET_ALL_REVIEWS_SUCCESS";
export const GET_ALL_REVIEWS_ERROR = "GET_ALL_REVIEWS_ERROR";

export const REPLY_REVIEW = "REPLY_REVIEW";
export const REPLY_REVIEW_SUCCESS = "REPLY_REVIEW_SUCCESS";
export const REPLY_REVIEW_ERROR = "REPLY_REVIEW_ERROR";

export const DELETE_REVIEW = "DELETE_REVIEW";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const DELETE_REVIEW_ERROR = "DELETE_REVIEW_ERROR";

//faq related actions
export const FAQ_LIST = "FAQ_LIST";
export const SELECTED_FAQ = "SELECTED_FAQ";
export const SELECTED_SECTION = "SELECTED_SECTION";

//inquiry details
export const SET_INQUIRY_LIST = "SET_INQUIRY_LIST";
export const SELECTED_INQUIRY = "SELECTED_INQUIRY";

export const GET_ALL_INQUIRY_DETAILS = "GET_ALL_INQUIRY_DETAILS";
export const GET_ALL_INQUIRY_DETAILS_SUCCESS = "GET_ALL_INQUIRY_DETAILS_SUCCESS";
export const GET_ALL_INQUIRY_DETAILS_ERROR = "GET_ALL_INQUIRY_DETAILS_ERROR";

export const GET_INQUIRY_DETAILS = "GET_INQUIRY_DETAILS";
export const GET_INQUIRY_DETAILS_SUCCESS = "GET_INQUIRY_DETAILS_SUCCESS";
export const GET_INQUIRY_DETAILS_ERROR = "GET_INQUIRY_DETAILS_ERROR";

export const UPDATE_INQUIRY = "UPDATE_INQUIRY";
export const UPDATE_INQUIRY_DETAILS = "UPDATE_INQUIRY_DETAILS";
export const UPDATE_INQUIRY_SUCCESS = "UPDATE_INQUIRY_SUCCESS";
export const UPDATE_INQUIRY_ERROR = "UPDATE_INQUIRY_ERROR";

export const GET_INQUIRY_DOWNLOAD = "GET_INQUIRY_DOWNLOAD";

export const DELETE_INQUIRY = "DELETE_INQUIRY";
export const DELETE_INQUIRY_SUCCESS = "DELETE_INQUIRY_SUCCESS";
export const DELETE_INQUIRY_ERROR = "DELETE_INQUIRY_ERROR";

//order details
export const GET_ORDER_DETAIL = "GET_ORDER_DETAIL";
export const GET_ORDER_DETAIL_SUCCESS = "GET_ORDER_DETAIL_SUCCESS";
export const GET_ORDER_DETAIL_ERROR = "GET_ORDER_DETAIL_ERROR";

export const ADD_ORDER_DATA = "ADD_ORDER_DATA";
export const ADD_ORDER_DATA_SUCCESS = "ADD_ORDER_DATA_SUCCESS";
export const ADD_ORDER_DATA_ERROR = "ADD_ORDER_DATA_ERROR";

export const GET_EXCEL_DATA = "GET_EXCEL_DATA";
export const GET_EXCEL_DATA_SUCCESS = "GET_EXCEL_DATA_SUCCESS";
export const GET_EXCEL_DATA_ERROR = "GET_EXCEL_DATA_ERROR";

//dashboard details
export const GET_DASHBOARD_DETAILS = "GET_DASHBOARD_DETAILS";
export const GET_DASHBOARD_DETAILS_SUCCESS = "GET_DASHBOARD_DETAILS_SUCCESS";
export const GET_DASHBOARD_DETAILS_ERROR = "GET_DASHBOARD_DETAILS_ERROR";

export const GET_PIECHART_DETAILS = "GET_PIECHART_DETAILS";
export const GET_PIECHART_DETAILS_SUCCESS = "GET_PIECHART_DETAILS_SUCCESS";
export const GET_PIECHART_DETAILS_ERROR = "GET_PIECHART_DETAILS_ERROR";

export const GET_WEEKLY_REVENUE_DETAILS = "GET_WEEKLY_REVENUE_DETAILS";
export const GET_WEEKLY_REVENUE_DETAILS_SUCCESS = "GET_WEEKLY_REVENUE_DETAILS_SUCCESS";
export const GET_WEEKLY_REVENUE_DETAILS_ERROR = "GET_WEEKLY_REVENUE_DETAILS_ERROR";

export const GET_WEEKLY_PRODUCTS_SELL = "GET_WEEKLY_PRODUCTS_SELL";
export const GET_WEEKLY_PRODUCTS_SELL_SUCCESS = "GET_WEEKLY_PRODUCTS_SELL_SUCCESS";
export const GET_WEEKLY_PRODUCTS_SELL_ERROR = "GET_WEEKLY_PRODUCTS_SELL_ERROR";

export const LAST_SIX_MONTH_REVENUE = "LAST_SIX_MONTH_REVENUE";
export const LAST_SIX_MONTH_REVENUE_SUCCESS = "LAST_SIX_MONTH_REVENUE_SUCCESS";
export const LAST_SIX_MONTH_REVENUE_ERROR = "LAST_SIX_MONTH_REVENUE_ERROR";

// Dashboard
export const GET_DASHBOARD = "GET_DASHBOARD";

//Farm
export const GET_FARM = "GET_FARM";
export const GET_FARM_SUCCESS = "GET_FARM";
export const GET_FARM_ERROR = "GET_FARM";
export const DELETE_FARM = "DELETE_FARM";

export const GET_FARM_NAME = "GET_FARM_NAME";

export const ADD_FARM = "ADD_FARM";
export const UPDATE_FARM = "UPDATE_FARM";

export const GET_FARM_DOWNLOAD = "GET_FARM_DOWNLOAD";

//Device
export const GET_DEVICE = "GET_DEVICE";
export const UPDATE_DEVICE = "UPDATE_DEVICE";
export const GET_DEVICE_DOWNLOAD = "GET_DEVICE_DOWNLOAD";

export const GET_DEVICE_ACTIVITY = "GET_DEVICE_ACTIVITY";

// Camera
export const GET_CAMERA = "GET_CAMERA";
export const UPDATE_CAMERA = "UPDATE_CAMERA";
export const GET_CAMERA_DOWNLOAD = "GET_CAMERA_DOWNLOAD";

export const GET_CAMERA_ACTIVITY = "GET_CAMERA_ACTIVITY";

// User
export const GET_USER = "GET_USER";
export const GET_USER_DOWNLOAD = "GET_USER_DOWNLOAD";

export const UPDATE_USER = "UPDATE_USER";

// News
export const GET_NEWS = "GET_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";
export const ADD_NEWS = "Add_NEWS";
export const UPDATE_NEWS = "UPDATE_NEWS";

export const GET_NEWS_DOWNLOAD = "GET_NEWS_DOWNLOAD";

// FAQS
export const GET_ALL_SECTION = "GET_ALL_SECTION";
export const ADD_SECTION = "ADD_SECTION";
export const DELETE_SECTIONS = "DELETE_SECTIONS";

export const CREATE_SECTIONS = "CREATE_SECTIONS";
export const UPDATE_SECTIONS = "UPDATE_SECTIONS";

export const GET_ALL_SECTION_SUCCESS = "GET_ALL_SECTION_SUCCESS";
export const GET_ALL_SECTION_ERROR = "GET_ALL_SECTION_ERROR";
export const GET_ALL_FAQS_SUCCESS = "GET_ALL_FAQS_SUCCESS";

export const CREATE_FAQ = "CREATE_FAQ";
export const GET_ALL_FAQ = "GET_ALL_FAQ";
export const UPDATE_FAQ = "UPDATE_FAQ";
export const DELETE_FAQ = "DELETE_FAQ";

export const GET_ALL_FAQS = "GET_ALL_FAQS";
export const GET_ALL_FAQS_ERROR = "GET_ALL_FAQS_ERROR";

export const CREATE_FAQS = "CREATE_FAQS";
export const CREATE_FAQS_SUCCESS = "CREATE_FAQS_SUCCESS";
export const CREATE_FAQS_ERROR = "CREATE_FAQS_ERROR";

export const UPDATE_FAQS = "UPDATE_FAQS";
export const UPDATE_FAQS_SUCCESS = "UPDATE_FAQS_SUCCESS";
export const UPDATE_FAQS_ERROR = "UPDATE_FAQS_ERROR";

export const DELETE_FAQS = "DELETE_FAQS";
export const DELETE_FAQS_SUCCESS = "DELETE_FAQS_SUCCESS";
export const DELETE_FAQS_ERROR = "DELETE_FAQS_ERROR";

export const GET_FAQS_BY_ID = "GET_FAQS_BY_ID";
export const GET_FAQS_BY_ID_SUCCESS = "GET_FAQS_BY_ID_SUCCESS";
export const GET_FAQS_BY_ID_ERROR = "GET_FAQS_BY_ID_ERROR";
export const GET_SECTIONS_BY_ID_ERROR = "GET_SECTIONS_BY_ID_ERROR";

export const CREATE_SECTIONS_SUCCESS = "CREATE_SECTIONS_SUCCESS";
export const CREATE_SECTIONS_ERROR = "CREATE_SECTIONS_ERROR";

export const UPDATE_SECTIONS_SUCCESS = "UPDATE_SECTIONS_SUCCESS";
export const UPDATE_SECTIONS_ERROR = "UPDATE_SECTIONS_ERROR";

export const DELETE_SECTIONS_SUCCESS = "DELETE_SECTIONS_SUCCESS";
export const DELETE_SECTIONS_ERROR = "DELETE_SECTIONS_ERROR";

export const GET_SECTIONS_BY_ID = "GET_SECTIONS_BY_ID";
export const GET_SECTIONS_BY_ID_SUCCESS = "GET_SECTIONS_BY_ID_SUCCESS";
